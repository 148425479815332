import React from 'react';
import { MargaretProvider } from '@tymate/margaret';
import styled, { createGlobalStyle } from 'styled-components';
import { theme, colors } from 'ui';
import Header from './Header';
import Footer from './Footer';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import '@fontsource/sarabun/700.css';
import '@fontsource/sarabun/400.css';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Emmett';
    src: url('/fonts/emmett.woff2') format('woff2'),
         url('/fonts/emmett.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    ${({ theme }) => theme.fontStyles.body}
    font-family: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.text};
  }

  img {
    display: block;
    max-width: 100%;
  }

  blockquote {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Site = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background};
`;

const Main = styled.main`
  position: relative;
  flex: 1;
`;

const Layout = ({
  children,
  globalData,
  mainMenuData,
  engagementKinds,
  variant,
}) => (
  <MargaretProvider theme={theme} colors={colors}>
    <GlobalStyle />

    <Site>
      <Header data={mainMenuData} variant={variant} />
      <Main id="content">{children}</Main>
      <Footer
        data={globalData}
        menuData={mainMenuData}
        engagementKinds={engagementKinds}
      />
    </Site>
  </MargaretProvider>
);

export default Layout;
