import { Text, Box } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const NavLink = styled(Link)`
  ${({ theme }) => theme.fontStyles.body}
  color: ${({ theme }) => theme.text};
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
  ${({ variant }) =>
    variant === 'footer' &&
    css`
      color: ${({ theme }) => theme.white};
    `}
`;

export const Logo = styled(Link)`
  width: 140px;
  height: 40px;

  ${({ theme }) => theme.media.tablet`
    width: 178px;
    height: 60px;
  `};
`;

export const Section = styled(Box).attrs(
  ({ paddingVertical, paddingLeft, paddingRight, position }) => ({
    paddingVertical: paddingVertical ?? {
      default: 2,
      tablet: 4,
      medium: 4,
      desktop: 4,
    },
    paddingLeft: paddingLeft ?? {
      default: 1,
      tablet: 10.5,
      medium: 11,
      desktop: 14,
    },
    paddingRight: paddingRight ?? {
      default: 1,
      tablet: 1,
      medium: 1,
      desktop: 1,
    },
    position: position ?? 'relative',
  }),
)`
  padding-top: 80px;

  ${({ overflowHidden }) =>
    overflowHidden &&
    css`
      max-width: 100%;
      overflow-x: hidden;
    `}

  ${({ theme }) => theme.media.tablet`
    ${({ variant }) =>
      variant === 'topSection' &&
      css`
        padding-top: 180px;
      `}
  `};

  ${({ variant }) =>
    variant === 'topSection' &&
    css`
      background-color: ${({ theme }) => theme.white};
      padding-left: 0;
      padding-right: 0;
    `};
`;

export const Overtitle = styled(Text).attrs(({ textAlign }) => ({
  type: 'h3',
  textAlign: textAlign ?? 'center',
}))`
  text-transform: uppercase;
`;

export const Title = styled(Text).attrs(({ textAlign }) => ({
  type: 'h1',
  as: 'h1',
  textAlign: textAlign ?? 'center',
}))``;

export const Subtitle = styled(Text).attrs(({ textAlign }) => ({
  type: 'h2',
  as: 'h2',
  textAlign: textAlign ?? 'center',
}))``;

export const Tag = styled(Text).attrs(({ textAlign }) => ({
  textAlign: textAlign ?? 'center',
  paddingHorizontal: 0.5,
  paddingTop: 0.25,
  paddingBottom: 0.375,
}))`
  width: max-content;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.yellow[500]};
  background-color: ${({ theme }) => theme.colors.yellow[100]};

  ${({ isNotSelected }) =>
    isNotSelected &&
    css`
      color: ${({ theme }) => theme.primary};
      background-color: transparent;

      &:hover {
        background-color: ${({ theme }) => theme.colors.yellow[50]};
      }
    `}

  transition: background-color 0.2s ease-in-out;
`;
