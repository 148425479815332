import React from 'react';
import { Stack, MainNav as MgtMainNav } from '@tymate/margaret';
import styled, { css, keyframes } from 'styled-components';
import logo from 'images/logo.svg';
import { Logo, NavLink } from 'ui';

const MainNav = styled(MgtMainNav)`
  height: auto;
  z-index: 5;
  padding: ${({ theme }) => theme.spacing(0)} ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
  margin-top: -0.75em;
  background-color: ${({ theme }) => theme.white};

  ${({ variant }) =>
    variant === 'homePage' &&
    css`
      background-color: ${({ theme }) => theme.background};
    `}
`;

const sunshine = keyframes`
  from {
    box-shadow: 0 0 0 0 hsla(35, 90%, 55%, 0.4);
  } 
  to {
    box-shadow: 0 0 0 55px hsla(35, 90%, 55%, 0);
  }
`;

const Sun = styled(Stack).attrs({
  alignX: 'center',
  alignY: 'center',
})`
  ${({ theme }) => theme.fontStyles.bodyLarge}
  position: relative;
  width: 115px;
  height: 115px;
  background-color: ${({ theme }) => theme.secondary};
  text-decoration: none;
  border-radius: 999em;
  display: none;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.tablet`
    display: flex;
  `}
`;

const NavBar = styled(Stack).attrs({
  gutterSize: { default: 1, tablet: 2 },
  direction: { default: 'column', tablet: 'row' },
  size: 'full',
  alignX: { default: 'flex-start', tablet: 'flex-end' },
})`
  ${({ theme }) => theme.media.tablet`
    display: flex;
  `};
`;

const Ray = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: ${sunshine} 6s infinite linear;
  animation-delay: ${({ offset }) => offset || 0}s;
  border-radius: 100%;
`;

const Trigger = styled.div`
  position: absolute;
  top: 0;
  z-index: 5;
  color: ${({ theme }) => theme.primary};

  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
`;

const CustomNavLink = styled(NavLink)`
  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
`;

export const Header = ({ data, variant }) => {
  return (
    <header>
      <MainNav
        variant={variant}
        direction={{ default: 'column', tablet: 'row' }}
      >
        <Stack
          gap={2}
          flexGrow={1}
          alignX="space-between"
          alignY={{ default: 'flex-start', tablet: 'center' }}
          direction={{ default: 'column', tablet: 'row' }}
          paddingTop={{ default: 2, tablet: 0 }}
        >
          <Logo variant="header" to="/" id="presentation">
            <img
              src={logo}
              alt="Logo de rêves de fandié"
              style={{ marginTop: '1em' }}
            />
          </Logo>

          <NavBar>
            <NavLink to="/">{data?.homepageLink}</NavLink>
            <NavLink to="/galerie">{data?.galleryLink}</NavLink>
            <NavLink to="/blog">{data?.blogLink}</NavLink>
            <CustomNavLink to="/engagement">
              {data?.involvmentLink}
            </CustomNavLink>
          </NavBar>
        </Stack>

        <Stack paddingLeft={{ default: 0, tablet: 2 }}>
          <Sun as="a" href="/engagement">
            <Ray />
            <Ray offset={3} />
            <span>{data?.involvmentLink}</span>
          </Sun>
        </Stack>
      </MainNav>

      <Trigger>
        <MainNav.Trigger />
      </Trigger>
    </header>
  );
};

export default Header;
