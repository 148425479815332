import React from 'react';
import styled, { css } from 'styled-components';
import { Stack, Text, Box } from '@tymate/margaret';

const Wrapper = styled(Stack).attrs({
  padding: 1.5,
  direction: 'column',
  alignY: 'center',
})`
  color: #ffffff;
  border-radius: 100%;
  background-color: var(--ui-secondary-button-color);
  text-align: center;
  max-width: 420px;
  max-height: 420px;
  img {
    height: 40px;
    width: 40px;
  }

  ${({ index }) =>
    index === 0 &&
    css`
      --ui-secondary-button-color: ${({ theme }) => theme.tertiary};
      --ui-secondary-button-color-hover: var(--colors-corail-700);
      width: 312px;
      height: 312px;

      ${({ variant }) =>
        variant === 'compact' &&
        css`
          width: 200px;
          height: 200px;

          ${({ theme }) => theme.media.desktop`
            width: 250px;
            height: 250px;
          `}
        `}
    `};

  ${({ index }) =>
    index === 1 &&
    css`
      --ui-secondary-button-color: var(--colors-green-600);
      --ui-secondary-button-color-hover: var(--colors-green-800);

      width: calc(100vw - ${({ theme }) => theme.spacing(2)});
      height: calc(100vw - ${({ theme }) => theme.spacing(2)});

      ${({ theme }) => theme.media.tablet`
        width: 372px;
        height: 372px;
      `}

      img {
        height: 46px;
        width: 46px;
      }

      ${({ variant }) =>
        variant === 'compact' &&
        css`
          width: 200px;
          height: 200px;

          ${({ theme }) => theme.media.desktop`
            width: 220px;
            height: 220px;
          `}
        `}
    `};

  ${({ index }) =>
    index === 2 &&
    css`
      --ui-secondary-button-color: ${({ theme }) => theme.secondary};
      --ui-secondary-button-color-hover: var(--colors-yellow-700);
      width: 256px;
      height: 256px;

      ${({ variant }) =>
        variant === 'compact' &&
        css`
          width: 200px;
          height: 200px;

          ${({ theme }) => theme.media.desktop`
            width: 170px;
            height: 170px;
          `}
        `}
    `};
`;

const Information = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 0.25,
  alignX: 'center',
})`
  ${({ theme }) => theme.fontStyles.bodySmall};
  background: rgba(255, 255, 255, 0.7);
  color: var(--ui-secondary-button-color);
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(0.25)};
  width: 100%;

  p {
    margin: 0;
  }
`;

const AnchorLink = styled.a`
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: var(--ui-secondary-button-color);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 74px;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(0.5)};

  &:hover {
    background-color: var(--ui-secondary-button-color-hover);
    color: ${({ theme }) => theme.colors.white};
  }

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`;

const Engagement = ({ engagement, variant, ...props }) => (
  <Wrapper {...props} variant={variant}>
    <Stack width="100%" direction="column" gap={1} alignX="center">
      <img src={engagement?.icon?.url} alt="" />
      <Box>
        <Text fontWeight={600} style={{ fontSize: 20, textAlign: 'center' }}>
          {engagement?.title}
        </Text>
      </Box>

      {variant !== 'compact' && (
        <>
          {engagement?.information && (
            <Information
              dangerouslySetInnerHTML={{
                __html: engagement?.information,
              }}
            />
          )}
          <Box>
            <Text type="bodySmall" style={{ textAlign: 'center' }}>
              {engagement?.description}
            </Text>
          </Box>
        </>
      )}

      {engagement?.ctaLink && engagement?.cta && (
        <AnchorLink
          href={engagement?.ctaLink}
          variant="secondary"
          target="blank"
          rel="nofollow noopener noreferer"
        >
          {engagement?.cta}
        </AnchorLink>
      )}
    </Stack>
  </Wrapper>
);

export default Engagement;
