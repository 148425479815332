export const colors = {
  palette: {
    white: '#ffffff',

    whiteAlpha: {
      100: 'rgba(255, 255, 255, 0.02)',
      200: 'rgba(255, 255, 255, 0.04)',
      300: 'rgba(255, 255, 255, 0.08)',
      400: 'rgba(255, 255, 255, 0.12)',
      500: 'rgba(255, 255, 255, 0.16)',
      600: 'rgba(255, 255, 255, 0.24)',
      700: 'rgba(255, 255, 255, 0.38)',
      800: 'rgba(255, 255, 255, 0.6)',
      900: 'rgba(255, 255, 255, 0.87)',
    },

    green: {
      50: '#eef6f4',
      100: '#d0e7df',
      200: '#b2d7cb',
      300: '#94c7b6',
      400: '#76b7a1',
      500: '#57a88d',
      600: '#315e4f',
      700: '#346555',
      800: '#234338',
      900: '#11221c',
    },

    orange: '#ec6830',

    corail: {
      50: '#fcede9',
      100: '#f6cdc1',
      200: '#f0ac99',
      300: '#e88062',
      400: '#e46b49',
      500: '#de4b21',
      600: '#b23c1a',
      700: '#852d14',
      800: '#591e0d',
      900: '#2c0f07',
    },

    blue: {
      50: '#ebeff9',
      100: '#c8d3ef',
      200: '#a4b7e4',
      300: '#819ada',
      400: '#5d7ed0',
      500: '#3a62c5',
      600: '#20366d',
      700: '#233b76',
      800: '#17274f',
      900: '#0c1427',
    },

    red: '#d52832',

    yellow: {
      50: '#fef4e7',
      100: '#fce1bb',
      200: '#f9cd8f',
      300: '#f7b964',
      400: '#f5a638',
      500: '#f59d24',
      600: '#c2750a',
      700: '#925807',
      800: '#613b05',
      900: '#311d02',
    },

    yellowLight: '#fbcca1',
  },

  ui: {
    text: 'var(--colors-blue-600)',
    textSecondary: 'rgba(32, 54, 109, 0.6)',
    textLight: 'var(--colors-white)',
    primary: 'var(--colors-blue-600)',
    secondary: 'var(--colors-yellow-500)',
    tertiary: 'var(--colors-corail-300)',
    background: '#fffaf3',
    white: '#ffffff',
  },
};

export const theme = {
  sidemenuWrapperHeight: '250px',

  mainNav: {
    position: 'top',
    width: '25rem',
    maxWidth: '100vw',
    widthTablet: '16rem',
    height: '55px',
    offCanvasToVisibleBreakpoint: 'tablet',
    background: {
      mobile: '#fffaf3',
      tablet: '#fffaf3',
    },
    triggerColor: {
      expanded: 'rgba(0, 0, 0, 0.87)',
      collapsed: 'rgba(0, 0, 0, 0.87)',
    },
  },

  button: {
    paddingVertical: '8px',
    paddingHorizontal: '8px',
    fontSize: '18px',
    fontWeight: 700,
    borderRadius: '139px',
    secondary: {
      color: 'var(--ui-secondary-button-color)',
      background: 'var(--colors-white)',
    },
  },

  fonts: {
    title:
      '"Emmett", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    body: '"Sarabun", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    system:
      '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  },

  fontStacks: {
    h1: {
      sizeMinRem: 2,
      sizeMaxRem: 3,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 700,
      defaultTag: 'h1',
      fontFamily: 'title',
    },
    h2: {
      sizeMinRem: 2,
      sizeMaxRem: 2.375,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 700,
      defaultTag: 'h2',
      fontFamily: 'title',
    },
    h3: {
      sizeMinRem: 1,
      sizeMaxRem: 1,
      lineHeightMin: 1.31,
      lineHeightMax: 1.31,
      fontWeight: 400,
      defaultTag: 'h3',
      color: 'tertiary',
    },
    heading: {
      sizeMinRem: 1.375,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.3,
      lineHeightMax: 1.5,
      fontWeight: 700,
      fontFamily: 'body',
    },
    subtitle: {
      sizeMinRem: 1.2,
      sizeMaxRem: 1.25,
      lineHeightMin: 1.3,
      lineHeightMax: 1.5,
      fontWeight: 700,
      fontFamily: 'body',
    },
    bodyLarge: {
      sizeMinRem: 1.1,
      sizeMaxRem: 1.125,
      lineHeightMin: 1.3,
      lineHeightMax: 1.5,
    },
    body: {
      sizeMinRem: 1,
      sizeMaxRem: 1,
      lineHeightMin: 1.3125,
      lineHeightMax: 1.5,
    },
    bodySmall: {
      sizeMinRem: 0.8125,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.4,
      lineHeightMax: 1.35,
    },
  },
};
