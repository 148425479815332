import React, { useRef } from 'react';
import { Stack, Container } from '@tymate/margaret';
import styled, { css, keyframes } from 'styled-components';
import { Section, Overtitle, Subtitle } from 'ui';
import { useTheme } from 'styled-components';

const infiniteScroll = keyframes`
  0% {transform: translateX(0%)}
  100% {transform: translateX(-100%)}
`;

const PartnersWrapper = styled.div`
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.spacing(2)};
  min-height: calc(150px + ${({ theme }) => theme.spacing(2)});
`;

const PartnersList = styled(Stack)`
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  min-height: 150px;

  ${({ speed }) =>
    speed &&
    css`
      animation-duration: ${speed * 8}s;
    `}

  ${props =>
    props.isAnimated &&
    css`
      animation-name: ${infiniteScroll};
    `}
`;

const PartnersSublist = styled(Stack)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

const Partner = styled.div`
  padding: 0 ${({ theme }) => theme.spacing(2)};

  img {
    object-fit: contain;
    max-width: 250px;
    height: 150px;
  }
`;

const PartnersSection = ({ data, partners }) => {
  const partnerListRef = useRef(null);
  const partnersSublist = useRef(null);
  const theme = useTheme();

  return (
    <Section
      paddingLeft={0}
      paddingRight={0}
      style={{
        zIndex: 5,
        backgroundColor: theme.background,
      }}
    >
      <Container paddingHorizontal={1}>
        <Stack
          direction="column"
          gap={0.5}
          flex={{ default: 2, desktop: 2 }}
          alignX="center"
        >
          {Boolean(data?.partnersOvertitle) && (
            <Overtitle>{data?.partnersOvertitle}</Overtitle>
          )}
          <Subtitle style={{ whiteSpace: 'pre-wrap' }}>
            {data?.partnersTitle}
          </Subtitle>
        </Stack>
      </Container>

      <PartnersWrapper>
        <PartnersList
          ref={partnerListRef}
          isAnimated
          speed={partners?.length}
          style={{ width: `${partnersSublist?.current?.clientWidth}px` }}
        >
          <PartnersSublist ref={partnersSublist}>
            {([...partners, ...partners, ...partners, ...partners] ?? []).map(
              ({ node }, index) => (
                <Partner key={index}>
                  <img src={node?.logo?.url} alt={node?.logo?.alt} />
                </Partner>
              ),
            )}
          </PartnersSublist>
        </PartnersList>
      </PartnersWrapper>
    </Section>
  );
};

export default PartnersSection;
