import React from 'react';
import styled from 'styled-components';
import { Container, Stack, Box } from '@tymate/margaret';
import logo from 'images/logo-white-full.svg';
import { AiOutlineTwitter, AiFillInstagram } from 'react-icons/ai';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import Engagement from './Engagement';
import footCloud1 from 'images/foot-clouds-1.svg';
import footCloud2 from 'images/foot-clouds-2.svg';
import { useTheme } from 'styled-components';
import { NavLink } from 'ui';

const FooterWrapper = styled.footer`
  position: relative;
  padding-top: 25vw;
  z-index: 5;
`;

const Engagements = styled(Stack).attrs({
  gap: { default: 1, desktop: -4 },
  direction: { default: 'column', tablet: 'row', desktop: 'column' },
  alignY: { default: 'center', desktop: 'unset' },
  alignX: { default: 'center', tablet: 'flex-start' },
})`
  ${({ theme }) => theme.media.desktop`
    position: relative;
    top: -6em;

    > *:nth-child(2) {
      order: -1;
    }

    > *:nth-child(1) {
      margin-left: -24em;
      margin-top: -4em;
    }
  `}
`;

const Clouds = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ zIndex }) => zIndex};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: top center;
  background-size: cover;
  height: 25vw;
`;

const FooterContent = styled.div`
  position: relative;
  z-index: 5;
  color: #ffffff;
  background-color: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing()};
`;

const FooterItems = styled(Stack).attrs({
  gap: 4,
  direction: { default: 'column', desktop: 'row' },
})``;

const FooterMainItem = styled.div`
  flex-grow: 1;

  ${({ theme }) =>
    theme.media.desktop`
    padding-right: 10em;
  `}
`;

const Anchors = styled(Stack).attrs({
  direction: 'column',
  gutterSize: { default: 0.5, tablet: 1 },
  alignX: 'space-between',
  alignY: 'flex-end',
  flexGrow: 1,
})``;

const AnchorLink = styled.a`
  color: white;
  text-decoration: none;
`;

const MailLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  text-transform: uppercase;
`;

const Logo = styled.img`
  margin-bottom: 0;
  max-width: 200px;
  width: 100%;
  background-size: contain;
`;

const Footer = ({ data, engagementKinds, menuData }) => {
  const theme = useTheme();
  return (
    <FooterWrapper
      style={{
        backgroundColor: theme.background,
      }}
    >
      <Clouds zIndex={4} backgroundImage={footCloud2} />
      <Clouds zIndex={5} backgroundImage={footCloud1} />
      <FooterContent>
        <Container>
          <FooterItems>
            <FooterMainItem>
              <Stack
                direction={{ default: 'column', tablet: 'row' }}
                gap={{ default: 2, tablet: 4 }}
              >
                <div>
                  <Logo src={logo} alt="Rêves de Fandié" />
                </div>
                <Stack
                  direction={{ default: 'column', tablet: 'row' }}
                  gap={{ default: 0.5, tablet: 2, desktop: 4 }}
                  alignX="flex-start"
                >
                  <Anchors>
                    <NavLink variant="footer" to="/">
                      {menuData?.homepageLink}
                    </NavLink>
                    <NavLink variant="footer" to="/galerie">
                      {menuData?.galleryLink}
                    </NavLink>
                  </Anchors>
                  <Anchors>
                    <NavLink variant="footer" to="/blog">
                      {menuData?.blogLink}
                    </NavLink>
                    <NavLink variant="footer" to="/engagement">
                      {menuData?.involvmentLink}
                    </NavLink>
                  </Anchors>

                  <Anchors>
                    {Boolean(data?.twitterLink) && (
                      <AnchorLink
                        href={data.twitterLink}
                        target="_blank"
                        rel="noopener noreferer"
                      >
                        <AiOutlineTwitter size={20} style={{ opacity: 0.5 }} />
                      </AnchorLink>
                    )}
                    {Boolean(data?.instagramLink) && (
                      <AnchorLink
                        href={data.instagramLink}
                        target="_blank"
                        rel="noopener noreferer"
                      >
                        <AiFillInstagram size={20} style={{ opacity: 0.5 }} />
                      </AnchorLink>
                    )}
                    {Boolean(data?.facebookLink) && (
                      <AnchorLink
                        href={data.facebookLink}
                        target="_blank"
                        rel="noopener noreferer"
                      >
                        <FaFacebookF size={20} style={{ opacity: 0.5 }} />
                      </AnchorLink>
                    )}
                    {Boolean(data?.linkedinLink) && (
                      <AnchorLink
                        href={data.linkedinLink}
                        target="_blank"
                        rel="noopener noreferer"
                      >
                        <FaLinkedinIn size={20} style={{ opacity: 0.5 }} />
                      </AnchorLink>
                    )}
                  </Anchors>
                </Stack>
              </Stack>
              <Stack paddingVertical={2}>{data?.footerText}</Stack>
              <Stack gap={2} style={{ opacity: 0.5, fontSize: '14px' }}>
                {Boolean(data?.mailContact) && (
                  <MailLink href={`mailto:${data.mailContact}`}>
                    Nous contacter
                  </MailLink>
                )}
                {Boolean(data?.terms) && (
                  <AnchorLink
                    href={data.terms}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Mentions légales
                  </AnchorLink>
                )}
              </Stack>
              <Stack paddingTop={1}>
                <span>
                  Conçu et développé par Tymate,{' '}
                  <AnchorLink
                    as="a"
                    target="_blank"
                    rel="noopener"
                    href="https://tymate.com"
                  >
                    Agence de conception et développement à Lille
                  </AnchorLink>
                </span>
              </Stack>
            </FooterMainItem>

            <Engagements>
              {engagementKinds.map((engagement, index) => (
                <Engagement
                  key={index}
                  index={index}
                  engagement={engagement}
                  variant="compact"
                />
              ))}
            </Engagements>
          </FooterItems>
        </Container>
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;
